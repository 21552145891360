import React from "react"
import Layout from "@components/layout"
import SetPasswordForm from "@components/_molecules/form_set_password"
// import Seo from "@components/seo"

const SetPassword = () => (
  <Layout>
    {/*<Seo title="Home" />*/}
    <main>
      <div className="section cc-store-home-wrap">
        <div className="container">
          <h1>Set Password</h1>
          <SetPasswordForm />
        </div>
      </div>
    </main>
  </Layout>
)

export default SetPassword
