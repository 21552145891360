import React, { Component } from "react"
import { Link } from "gatsby"
import "./style.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"
// import { cl } from "@services/consolelogger"
import LineItemStatusIcon from "@components/_atoms/icon_line_item_status"

class SetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: ``,
      newpassword: ``,
      newpasswordconfirm: ``,
      buttonDisabled: true,
      pwChangeSuccess: false,
      waitingForResponse: false,
      error: "",
      charMin: 8,
      validUpperLower: false,
      validNumber: false,
      validCharLength: false,
      validPwMatch: false,
    }
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search)
    let token = params.get("token")
    this.setState({ token })
  }

  pwChangeHandler(ev) {
    let { newpassword, newpasswordconfirm } = this.state
    let { name, value } = ev.target
    let buttonDisabled = false
    if (name === "newpassword") newpassword = value
    if (name === "newpasswordconfirm") newpasswordconfirm = value
    let validPwMatch = !!(newpassword === newpasswordconfirm)
    this.setState({ buttonDisabled, error: ``, [name]: value, validPwMatch })
    if (name === "newpassword") {
      let validUpperLower, validNumber, validCharLength
      validUpperLower = !!(value.match(/[a-z]/) && value.match(/[A-Z]/))
      validNumber = !!value.match(/\d/)
      validCharLength = value.length >= this.state.charMin
      this.setState({ validUpperLower, validNumber, validCharLength })
    }
  }

  submitHandler(ev) {
    ev.preventDefault()
    let { newpassword, newpasswordconfirm, token } = this.state
    if (newpassword.length < 8) {
      this.setState({ error: `Password must be at least 8 characters long` })
    } else if (newpassword !== newpasswordconfirm) {
      this.setState({ error: `Passwords must match` })
    } else {
      this.setState({ buttonDisabled: true, waitingForResponse: true })
      let data = { token, password: newpassword }
      let options = {
        method: "POST",
        data: JSON.stringify(data),
        url: `${process.env.API_URL}/password-reset/confirm`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
      axios(options)
        .then((res) => {
          if (typeof window !== `undefined`) window.sessionStorage.clear()
          this.setState({ pwChangeSuccess: true, waitingForResponse: false })
        })
        .catch((err) => {
          this.setState({
            buttonDisabled: false,
            waitingForResponse: false,
            error: true,
          })
        })
    }
  }

  render() {
    let {
      newpassword,
      newpasswordconfirm,
      buttonDisabled,
      waitingForResponse,
      pwChangeSuccess,
      error,
      validUpperLower,
      validNumber,
      validCharLength,
      validPwMatch,
    } = this.state
    return (
      <div className="reset-password-form">
        {!pwChangeSuccess ? (
          <form onSubmit={this.submitHandler.bind(this)} noValidate>
            <p style={{ maxWidth: `450px`, margin: `2rem auto 1rem` }}>
              Please set your new password. Passwords must meet the following
              requirements:
            </p>
            <div className="requirements">
              <ul>
                <li>
                  <LineItemStatusIcon status={validUpperLower ? `valid` : ``} />
                  At least 1 Uppercase and 1 Lowercase
                </li>
                <li>
                  <LineItemStatusIcon status={validNumber ? `valid` : ``} />
                  At least 1 number
                </li>
                <li>
                  <LineItemStatusIcon status={validCharLength ? `valid` : ``} />
                  At least 8 characters
                </li>
                <li>
                  <LineItemStatusIcon status={validPwMatch ? `valid` : ``} />
                  Passwords Match
                </li>
              </ul>
            </div>
            <label htmlFor="email">New Password</label>
            <input
              id="newpassword"
              name="newpassword"
              onChange={this.pwChangeHandler.bind(this)}
              type="password"
              value={newpassword}
              autocomplete="new-password"
            />
            <label htmlFor="password">Confirm Password</label>
            <input
              id="newpasswordconfirm"
              name="newpasswordconfirm"
              onChange={this.pwChangeHandler.bind(this)}
              type="password"
              value={newpasswordconfirm}
              autocomplete="new-password"
            />
            <Button
              type="submit"
              disabled={
                buttonDisabled ||
                !validUpperLower ||
                !validNumber ||
                !validCharLength ||
                !validPwMatch
              }
            >
              {waitingForResponse ? `Sending` : `Change Password`}
            </Button>
            {error !== "" && <p className="error-text">{error}</p>}
          </form>
        ) : (
          <div style={{ textAlign: `center` }}>
            <p>Your password has been successfully changed</p>
            <p>
              <Link to="/services">Login</Link>
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default SetPasswordForm
