import React from "react"
import "./style.scss"

const LineItemStatusIcon = ({ status }) => (
  <span className={`line-item-status-icon ${status}`}>
    <div className="first"></div>
    <div className="second"></div>
  </span>
)

export default LineItemStatusIcon
